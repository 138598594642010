import { FC, useMemo } from 'react';
import { IAlert } from '../../../../../api/dtos/alert';
import { useAlertGetAlert, useAlertGetAlertItems } from '../../../../../api/alert';
// import { useParams } from 'react-router-dom';
import AlertInfoItem from './AlertInfoItem';
import Pagination from '../../../../../containers/Pagination';
import Sidebar from '../../../../ui/components/Sidebar';
import CompassLoader from '../../../../ui/components/Loader/CompassLoader';
import Tab from '../../../../ui/components/Tab/Tab';
import classNames from 'classnames';
import EmptyState from '../../../../../ui/components/States/Empty';
import CustomerTransactions from '../../../../../components/Customer/CustomerTransactions/CustomerTransactions';
import { subtractDateAndDays, subtractDateAndMinutes } from '../../../../../utils/helpers/date';

interface IAlertInfoProps {
  alert: IAlert;
  type: 'address' | 'transaction' | 'customer';
  id: string | number;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export interface IRiskTypePayload {
  riskType1Payload: ICustomerBehaviorParams;
  riskType2Payload: ICustomerBehaviorParams;
  showBothModes: boolean;
}

export interface ICustomerBehaviorParams {
  transactionStartTime?: string;
  transactionEndTime?: string;
  incoming_value_usd__gte?: number;
  identifier?: string;
}

const AlertInfo: FC<IAlertInfoProps> = ({ alert, type, id, open, setOpen }) => {
  // const { addressId } = useParams();
  // TODO: use react-router
  const customerBehavior = alert.rule_register.policy_category === 2 && alert.entity_type === 'customer';
  const alertItems = useAlertGetAlertItems(
    {
      id,
      alertId: alert.id,
      type,
    },
    {
      enabled: open,
    }
  );
  const { data: alertData, isLoading: detailLoading } = useAlertGetAlert(
    {
      id,
      alertId: alert.id,
      type,
    },
    {
      enabled: customerBehavior && open,
    }
  );
  const ruleConditionTypes = [7, 8, 10, 11, 13, 14, 15, 16, 17];
  const hasValidRuleConditionType = alertData?.data?.latest_context?.some((context) =>
    ruleConditionTypes.includes(context.rule_condition_type)
  );
  const tabs = useMemo(() => {
    return [
      { label: 'Overview' },
      {
        label: 'Relevant Transactions',
        hidden: !customerBehavior || !hasValidRuleConditionType,
      },
    ];
  }, [hasValidRuleConditionType, customerBehavior]);
  function getOldestDate(date1, date2) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);

    return d1 < d2 ? date1 : date2;
  }

  const getAlertParams = (): IRiskTypePayload => {
    const latestContext = alertData?.data?.latest_context;
    if (!latestContext || latestContext.length === 0) return undefined;

    const riskTypePayload: ICustomerBehaviorParams[] = [
      {
        transactionStartTime: undefined,
        transactionEndTime: undefined,
        incoming_value_usd__gte: undefined,
        identifier: undefined,
      },
      {
        transactionStartTime: undefined,
        transactionEndTime: undefined,
        incoming_value_usd__gte: undefined,
        identifier: undefined,
      },
    ];
    let showBothModes = false;

    latestContext.forEach(
      ({
        risk_type,
        rule_condition_type,
        analysis_window,
        transaction_amount_threshold,
        transaction_id,
        transit_window,
      }) => {
        const riskPayload = riskTypePayload[risk_type - 1];

        if (rule_condition_type === 8) showBothModes = true;

        if ([7, 15, 16, 17].includes(rule_condition_type)) {
          const startTime =
            rule_condition_type === 7
              ? subtractDateAndDays(alert.opened_at, Number(analysis_window))
              : subtractDateAndMinutes(alert.opened_at, Number(analysis_window));
          riskPayload.transactionStartTime = riskPayload?.transactionStartTime
            ? getOldestDate(riskPayload.transactionStartTime, startTime)
            : startTime;

          riskPayload.transactionEndTime = alert.opened_at;

          if (rule_condition_type === 17) {
            riskPayload.incoming_value_usd__gte =
              riskPayload?.incoming_value_usd__gte !== undefined
                ? Math.min(riskPayload.incoming_value_usd__gte, Number(transaction_amount_threshold))
                : Number(transaction_amount_threshold);
          }
        }
        const updateRiskTypePayload = (index: number, startTime: string) => {
          riskTypePayload[index].transactionStartTime = riskTypePayload[index]?.transactionStartTime
            ? getOldestDate(riskTypePayload[index].transactionStartTime, startTime)
            : startTime;
          riskTypePayload[index].transactionEndTime = alert.opened_at;
        };

        if (rule_condition_type === 8) {
          const startTime = subtractDateAndMinutes(alert.opened_at, Number(transit_window));
          updateRiskTypePayload(0, startTime);
          updateRiskTypePayload(1, startTime);
        }

        if ([10, 11, 13, 14].includes(rule_condition_type) && riskPayload?.identifier === undefined) {
          riskPayload.identifier = transaction_id;
        }
      }
    );

    return {
      riskType1Payload: riskTypePayload[0],
      riskType2Payload: riskTypePayload[1],
      showBothModes,
    };
  };

  const queryParams = useMemo(() => {
    return getAlertParams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertData]);

  return (
    <Sidebar
      maxWidthClass='max-w-4xl'
      title={
        <div className='flex flex-row'>
          <div className='mr-2 text-black'>
            <div>Rule Name</div>
            <div>Risk Level</div>
          </div>
          <div>
            <div>: {alert.alert_body}</div>
            <div>: {alert.level_verbose}</div>
          </div>
        </div>
      }
      isOpen={open}
      onClose={() => setOpen(false)}>
      <Tab
        values={tabs}
        type='secondary'
        listClassName={classNames('border-b border-gray-200 pb-2 sticky z-30 top-0 !mt-0')}
        panelClassName='pt-3'>
        <div className='border'>
          <Pagination query={alertItems}>
            {(item) => (
              <AlertInfoItem id={id} alert={alert} alertItem={item} key={item.identifier} type={type} />
            )}
          </Pagination>
        </div>
        {customerBehavior &&
          (detailLoading ? (
            <CompassLoader />
          ) : alertData ? (
            <CustomerTransactions customerId={id.toString()} noEmptySpaceHeight params={queryParams} />
          ) : (
            <EmptyState />
          ))}
      </Tab>
    </Sidebar>
  );
};

export default AlertInfo;
