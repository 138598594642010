import { AxiosError } from 'axios';
import { transactionTypes, transactionDirections } from '../constants/constants';
import { startCase } from 'lodash';
import { getNextPageParamByOffset } from './react-query.helper';

// export type Entity =
//   | 'Law Enforcement'
//   | 'Donation'
//   | 'Defi'
//   | 'DeFi'
//   | 'Smart Contract Platform'
//   | 'Service'
//   | 'Coin Mixer'
//   | 'Gambling'
//   | 'Exchange'
//   | 'Mining Pool'
//   | 'Sanctions'
//   | 'Darknet'
//   | 'Scam'
//   | 'Extortion'
//   | 'High Risk Organization'
//   | 'Malware'
//   | 'Theft'
//   | 'Others';

export type Risk = 'Critical' | 'High' | 'Medium' | 'Caution' | 'Info' | 'No Risk';

export const risks: {
  [key: string]: {
    shortName?: string;
    color: string;
    borderColor: string;
    bgColor: string;
    fontColor: string;
    colorHex: string;
  };
} = {
  Critical: {
    color: 'text-red-700',
    borderColor: 'border-red-700',
    bgColor: 'bg-red-700',
    fontColor: 'text-white',
    colorHex: '#b91c1c',
  },
  High: {
    color: 'text-red-500',
    borderColor: 'border-red-500',
    bgColor: 'bg-red-500',
    fontColor: 'text-white',
    colorHex: '#ef4444',
  },
  Medium: {
    color: 'text-red-400',
    borderColor: 'border-red-400',
    bgColor: 'bg-red-400',
    fontColor: 'text-white',
    colorHex: '#f87171',
  },
  Caution: {
    color: 'text-yellow-300',
    borderColor: 'border-yellow-300',
    bgColor: 'bg-yellow-300',
    fontColor: 'text-white',
    colorHex: '#fcd34d',
  },
  Info: {
    color: 'text-yellow-100',
    borderColor: 'border-yellow-100',
    bgColor: 'bg-yellow-100',
    fontColor: 'text-white',
    colorHex: '#fef3c7',
  },
  'No Risk': {
    color: 'text-gray-100',
    borderColor: 'border-gray-100',
    bgColor: 'bg-gray-100',
    fontColor: 'text-white',
    colorHex: '#f3f4f6',
  },
};

export const isDayBefore = (day: Date | string, from: Date | string) => {
  return new Date(new Date(day).toDateString()) < new Date(new Date(from).toDateString());
};

// export const getEntityTypeBG = (entity: Entity | string): { bgColor: string } => {
//   if (entity === 'unknown' || Object.keys(entityTypes).indexOf(entity) < 0) {
//     return { bgColor: 'bg-gray-200' };
//   }
//   return { bgColor: entityTypes[entity].bgColor };
// };

// export const getEntityTypeFontColor = (entity: Entity | string): { fontColor: string } => {
//   if (entity === 'unknown' || Object.keys(entityTypes).indexOf(entity) < 0) {
//     return { fontColor: 'text-white' };
//   }
//   return { fontColor: entityTypes[entity].fontColor };
// };

export const getRiskBG = (risk: Risk | string): { bgColor: string } => {
  if (Object.keys(risks).indexOf(risk) < 0) {
    return { bgColor: 'bg-gray-200' };
  }
  return { bgColor: risks[risk].bgColor };
};

export const getRiskFontColor = (risk: Risk | string): { fontColor: string } => {
  if (Object.keys(risks).indexOf(risk) < 0) {
    return { fontColor: 'text-white' };
  }
  return { fontColor: risks[risk].fontColor };
};

export const ellipsis = (str: string, limit: number, atEnd?: boolean) => {
  const eachSide = Math.floor(limit / 2);
  return str.length > limit ? str.slice(0, eachSide) + '...' + (!atEnd ? str.slice(eachSide * -1) : '') : str;
};

export const getNextPageParam = getNextPageParamByOffset;
export const getErrorMessage = (error: AxiosError<unknown>, showDefault = false) => {
  let errorObject = Object.values(error?.response?.data || {})?.[0];
  if (errorObject && typeof errorObject === 'string') {
    return errorObject;
  }
  errorObject = errorObject?.[0];
  if (errorObject && typeof errorObject === 'object') {
    const firstValue = Object.values(errorObject)?.[0];
    if (Array.isArray(firstValue)) {
      return firstValue[0];
    }
    return firstValue;
  }
  return errorObject || (showDefault ? 'Something went wrong' : '');
};

export const transactionTypesOptions = transactionTypes.map((type) => ({
  label: type.label,
  value: type.value,
}));

export const transactionDirectionOptions = transactionDirections.map((type) => ({
  label: type.label,
  value: type.value,
}));

export const getTransactionTypeByValue = (types) => {
  return startCase(
    transactionTypes.find((t) => {
      return t.types.some((ty) => types.includes(ty));
    })?.value
  );
};

export const getTransactionTypeValue = (type: string) => {
  if (!type) return [1, 2, 3, 4, 7, 8];
  return transactionTypes.find((t) => t.value === type)?.types;
};

export const sanitizeAndEncode = (input: string) => {
  const unwantedCharactersRegex = /[\u200B-\u200D\uFEFF]/g;
  const sanitizedInput = input?.replace(unwantedCharactersRegex, '');
  const encodedInput = encodeURIComponent(sanitizedInput?.trim());
  return encodedInput;
};

export const getShowSubTabsObject = (pathname, tabs) => {
  const result = tabs.tabs.reduce((acc, tab) => {
    if (tab.tabs) {
      acc[tab.name] = tab.tabs.some((innerTab) => pathname.includes(innerTab.name));
    }
    return acc;
  }, {});

  return result;
};

export const isAllUndefined = (obj) => Object.values(obj).every((value) => value === undefined);
