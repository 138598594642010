import { useQuery, UseQueryOptions, useInfiniteQuery } from 'react-query';
import { Api } from './api';
import { IMessageResponse } from './dtos/common';
import {
  ICustomerListParams,
  ICustomerListResponse,
  ICustomerPayload,
  ICreateCustomerResponse,
  IAddDepositWithdrawalRequest,
  ICustomerAddDepositAddressRequest,
  ICustomerAddressResponse,
  ICustomerArchiveAddressRequest,
  ICustomerDepositWithdrawalRequest,
  ICustomerDepositWithdrawalResponse,
  ICustomerEditCustomerIdRequest,
  ICustomerEditCustomerIdResponse,
  ICustomerReportRequest,
  ICustomerResponse,
  ICustomerTokensResponse,
  IDepositWithdrawalRequest,
  ICustomerExportList,
  ICustomerUpdateAlerts,
  ICustomerAddressRequest,
  ICustomerPofSummaryResponse,
  ICustomerPofAddressSummaryResponse,
  ICustomerPofAddressSummaryRequest,
} from './dtos/customer';
import {
  IAddressTransactionsExportResponse,
  IAggregatedResult,
  ISentimentListRequest,
  ISentimentListResponse,
  ISentimentSummaryResponse,
} from './dtos/address';
import { getNextPageParam } from '../utils/helpers/helperFunctions';

// CustomerApi.ts
export class CustomerApi extends Api {
  readonly baseUrl: string = '/risk/api/v4/customers';

  readonly getCustomerList = (params: ICustomerListParams) => {
    const { offset, filters, assigned_to, workflow_stage, closed } = params;

    if (closed) {
      return this.http.get<ICustomerListResponse>(this.route(`/`), {
        params: { assigned_to, limit: 20, offset, ...filters, workflow_stage },
      });
    }
    return this.http.get<ICustomerListResponse>(this.route(`/?offset=${offset}`), {
      params: { assigned_to, workflow_stage, limit: 20, offset, ...filters },
    });
  };

  readonly getCustomer = (customerId: string) => {
    return this.http.get<ICustomerResponse>(this.route(customerId + '/'));
  };

  readonly addCustomer = (payload: ICustomerPayload) => {
    return this.http.post<ICreateCustomerResponse>(this.route('/'), {
      ...payload,
    });
  };

  readonly exportCustomerList = (params: ICustomerExportList) => {
    const { ids, filters, assigned_to, workflow_stage, closed } = params;

    if (closed) {
      return this.http.get<IAddressTransactionsExportResponse>(this.route(`/export/?ids=${ids.join(',')}`), {
        params: { assigned_to, ...filters, workflow_stage },
      });
    }
    return this.http.get<IAddressTransactionsExportResponse>(this.route(`/export/?ids=${ids.join(',')}`), {
      params: { assigned_to, workflow_stage, ...filters },
    });
  };

  readonly updateCustomerAlerts = (params: ICustomerUpdateAlerts) => {
    const { ids, filters, assigned_to, workflow_stage, closed, comment, updated_status } = params;

    if (closed) {
      return this.http.put<IAddressTransactionsExportResponse>(
        this.route(`/update-alerts/?ids=${ids.join(',')}`),
        {
          workflow_stage: updated_status,
          ...(comment && { comment: { body: comment, context: { alerts: [], alert_items: [], users: [] } } }),
        },
        {
          params: {
            assigned_to,
            ...filters,
            workflow_stage,
          },
        }
      );
    }
    return this.http.put<IAddressTransactionsExportResponse>(
      this.route(`/update-alerts/?ids=${ids.join(',')}`),
      {
        workflow_stage: updated_status,
        ...(comment && { comment: { body: comment, context: { alerts: [], alert_items: [], users: [] } } }),
      },
      {
        params: {
          assigned_to,
          workflow_stage,
          ...filters,
        },
      }
    );
  };

  readonly getCustomerReport = (params: ICustomerReportRequest) => {
    const { id, entities } = params;
    return this.http.get(this.route(`${id}/report/`), {
      params: { entities },
      responseType: 'blob',
    });
  };

  readonly archiveCustomer = (customerId: string) => {
    return this.http.delete(this.route(`${customerId}/`));
  };

  readonly editCustomerId = (params: ICustomerEditCustomerIdRequest) => {
    const { customer_id, new_customer_id } = params;
    return this.http.put<ICustomerEditCustomerIdResponse>(this.route(`${customer_id}/`), {
      customer_id: new_customer_id,
    });
  };

  readonly addDepositAddress = (params: ICustomerAddDepositAddressRequest) => {
    const { customer_id, identifier, currency } = params;
    return this.http.post<IMessageResponse>(this.route(`${customer_id}/deposit-addresses/`), {
      identifier,
      currency,
    });
  };

  readonly addWithdrawalAddress = (params: ICustomerAddDepositAddressRequest) => {
    const { customer_id, identifier, currency } = params;
    return this.http.post<IMessageResponse>(this.route(`${customer_id}/withdrawal-addresses/`), {
      identifier,
      currency,
    });
  };

  readonly addAnyAddress = (params: ICustomerAddDepositAddressRequest) => {
    const { customer_id, identifier, currency } = params;
    return this.http.post<IMessageResponse>(this.route(`${customer_id}/any-addresses/`), {
      identifier,
      currency,
    });
  };

  readonly getTokenSummary = (customerId: string) => {
    return this.http.get<ICustomerTokensResponse>(this.route(`${customerId}/balances/`));
  };

  readonly getDepositAddresses = (params: ICustomerAddressRequest) => {
    return this.http.get<ICustomerAddressResponse>(this.route(`${params.customerId}/deposit-addresses/`), {
      params: { offset: params.offset, limit: params?.limit ?? 20 },
    });
  };

  readonly getAnyAddress = (params: ICustomerAddressRequest) => {
    return this.http.get<ICustomerAddressResponse>(this.route(`${params.customerId}/any-addresses/`), {
      params: { offset: params.offset, limit: params?.limit ?? 20 },
    });
  };

  readonly getWithdrawalAddresses = (params: ICustomerAddressRequest) => {
    return this.http.get<ICustomerAddressResponse>(this.route(`${params.customerId}/withdrawal-addresses/`), {
      params: { offset: params.offset, limit: params?.limit ?? 20 },
    });
  };

  readonly archiveDepositAddress = (args: ICustomerArchiveAddressRequest) => {
    const { customerId, address } = args;
    return this.http.delete(this.route(`${customerId}/deposit-addresses/${address}/`));
  };

  readonly archiveWithdrawalAddress = (args: ICustomerArchiveAddressRequest) => {
    const { customerId, address } = args;
    return this.http.delete(this.route(`${customerId}/withdrawal-addresses/${address}/`));
  };

  readonly archiveAnyAddress = (args: ICustomerArchiveAddressRequest) => {
    const { customerId, address } = args;
    return this.http.delete(this.route(`${customerId}/any-addresses/${address}/`));
  };

  readonly getDepositTag = (customerId: string) => {
    return this.http.get<{ results: IAggregatedResult[] }>(
      this.route(`${customerId}/deposits-tag-aggregates/`)
    );
  };

  readonly getWithdrawalTag = (customerId: string) => {
    return this.http.get<{ results: IAggregatedResult[] }>(
      this.route(`${customerId}/withdrawals-tag-aggregates/`)
    );
  };

  readonly getDeposits = (args: ICustomerDepositWithdrawalRequest) => {
    const { customerId, ...filters } = args;
    return this.http.get<ICustomerDepositWithdrawalResponse>(this.route(`${customerId}/deposits/`), {
      params: filters,
    });
  };

  readonly getWithdrawals = (args: ICustomerDepositWithdrawalRequest) => {
    const { customerId, ...filters } = args;
    return this.http.get<ICustomerDepositWithdrawalResponse>(this.route(`${customerId}/withdrawals/`), {
      params: filters,
    });
  };

  readonly addDepositWithdrawal = (args: IAddDepositWithdrawalRequest) => {
    const { customerId, type, address, currency, transaction } = args;
    return this.http.post(this.route(`${customerId}/${type}s/`), {
      to_address: address,
      currency,
      identifier: transaction,
    });
  };

  readonly exportDepositWithdrawal = (args: IDepositWithdrawalRequest) => {
    const { customerId, type } = args;
    return this.http.get(this.route(`${customerId}/${type}/export/`));
  };

  readonly getSentimentSummary = (id: string | number) => {
    return this.http.get<ISentimentSummaryResponse>(this.route(`${id}/sentiment_summary/`));
  };
  readonly getSentimentList = (params: ISentimentListRequest) => {
    const { id, offset = 0, limit = 15 } = params;
    return this.http.get<ISentimentListResponse>(this.route(`${id}/sentiments/`), {
      params: { offset, limit },
    });
  };
  readonly getPofCustomerSummary = (id: string | number) => {
    return this.http.get<ICustomerPofSummaryResponse>(this.route(`${id}/pof-customer-summary/`));
  };
  readonly getPofCustomerAddressSummary = (params: ICustomerPofAddressSummaryRequest) => {
    const { customerId, offset = 0, limit = 15 } = params;
    return this.http.get<ICustomerPofAddressSummaryResponse>(
      this.route(`${customerId}/pof-address-summary/`),
      {
        params: { offset, limit },
      }
    );
  };
}

export const customerApi = new CustomerApi();

export const useCustomerGetCustomerList = (params: ICustomerListParams, options?: UseQueryOptions) => {
  const key = [
    'customerApi.getCustomerList',
    params.filters,
    params.assigned_to,
    params.workflow_stage,
    params?.closed,
  ];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => customerApi.getCustomerList({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );

  return result;
};

export const useCustomerGetCustomer = (customerId: string, options?: UseQueryOptions) => {
  const key = ['customerApi', customerId, 'customerApi.getCustomer'];
  const result = useQuery(key, () => customerApi.getCustomer(customerId), { ...options } as unknown);

  return result;
};

export const useCustomerGetTokenSummary = (customerId: string, options?: UseQueryOptions) => {
  const key = ['customerApi', customerId, 'getTokenSummary'];
  const result = useQuery(key, () => customerApi.getTokenSummary(customerId), { ...options } as unknown);

  return result;
};

export const useCustomerGetDepositAddresses = (
  params: ICustomerAddressRequest,
  options?: UseQueryOptions
) => {
  const key = ['customerApi', params.customerId, 'getDepositAddresses'];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => customerApi.getDepositAddresses({ ...params, offset: pageParam }),
    {
      getNextPageParam,
      ...options,
    } as unknown
  );

  return result;
};

export const useCustomerGetWithdrawalAddresses = (
  params: ICustomerAddressRequest,
  options?: UseQueryOptions
) => {
  const key = ['customerApi', params.customerId, 'getWithdrawalAddresses'];

  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => customerApi.getWithdrawalAddresses({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};

export const useCustomerGetAnyAddresses = (params: ICustomerAddressRequest, options?: UseQueryOptions) => {
  const key = ['customerApi', params.customerId, 'getAnyAddresses'];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => customerApi.getAnyAddress({ ...params, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );

  return result;
};

export const useCustomerGetDepositTag = (customerId: string, options?: UseQueryOptions) => {
  const key = ['customerApi', customerId, 'getDepositTag'];
  const result = useQuery(key, () => customerApi.getDepositTag(customerId), { ...options } as unknown);

  return result;
};

export const useCustomerGetWithdrawalTag = (customerId: string, options?: UseQueryOptions) => {
  const key = ['customerApi', customerId, 'getWithdrawalTag'];
  const result = useQuery(key, () => customerApi.getWithdrawalTag(customerId), { ...options } as unknown);

  return result;
};

export const useCustomerGetDeposits = (
  args: ICustomerDepositWithdrawalRequest,
  options?: UseQueryOptions
) => {
  const key = ['customerApi', args.customerId, 'getDeposits', args];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => customerApi.getDeposits({ ...args, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );

  return result;
};

export const useCustomerGetWithdrawals = (
  args: ICustomerDepositWithdrawalRequest,
  options?: UseQueryOptions
) => {
  const key = ['customerApi', args.customerId, 'getWithdrawals', args];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => customerApi.getWithdrawals({ ...args, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );

  return result;
};

export const useCustomerGetSentimentSummary = (customerId: string, options?: UseQueryOptions) => {
  const key = ['customerApi', customerId, 'getSentimentSummary'];
  const result = useQuery(key, () => customerApi.getSentimentSummary(customerId), { ...options } as unknown);

  return result;
};

export const useCustomerGetSentimentList = (customerId: string, options?: UseQueryOptions) => {
  const key = ['customerApi', customerId, 'getSentimentList'];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => customerApi.getSentimentList({ id: customerId, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};

export const useCustomerGetPofCustomerSummary = (customerId: string, options?: UseQueryOptions) => {
  const key = ['customerApi', customerId, 'getPofCustomerSummary'];
  const result = useQuery(key, () => customerApi.getPofCustomerSummary(customerId), {
    ...options,
  } as unknown);

  return result;
};

export const useCustomerGetPofAddressSummary = (customerId: string, options?: UseQueryOptions) => {
  const key = ['customerApi', customerId, 'getPofCustomerList'];
  const result = useInfiniteQuery(
    key,
    ({ pageParam = 0 }) => customerApi.getPofCustomerAddressSummary({ customerId, offset: pageParam }),
    {
      ...options,
      getNextPageParam,
    } as unknown
  );
  return result;
};
