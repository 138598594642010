import { format, formatDistanceToNowStrict, formatDuration } from 'date-fns';

export function convertUnixTimeToDateTime(unixTime: number): number {
  return unixTime * 1000;
}

export function formatDateTime(date: string | number, tz?: string): string {
  date = tz ? convertTz(date, tz) : date;
  return date ? format(new Date(date), 'PP p') : '-';
}

export function convertTz(date: string | number, tz: string): string {
  if (!date) return '';
  return new Date(date).toLocaleString('en-US', { timeZone: tz });
}

export function formatOnlyDate(date: string | number, tz?: string): string {
  date = tz ? convertTz(date, tz) : date;
  return date ? format(new Date(date), 'dd MMMM yyyy') : '-';
}

export function formatDateShort(date: string | number, tz?: string): string {
  date = tz ? convertTz(date, tz) : date;
  return date ? format(new Date(date), 'PP') : '-';
}

export function formatDayOfYear(date: string | number): string {
  return date ? format(new Date(date), 'dd MMMM') : '-';
}

export function dateToString(date: Date): string {
  return format(date, 'yyyy-MM-dd');
}

export function formatMonthYear(date: string | number): string {
  return date ? format(new Date(date), 'MMM yyyy') : '-';
}

export function formatDateTimeHour(date: string | number): string {
  return date ? format(new Date(date), 'yyyy-MM-dd HH:mm') : '-';
}

export function listOfMonths(): string[] {
  const months = [];
  for (let i = 0; i <= 12; i++) {
    months.push(format(new Date(0, i), 'MMMM'));
  }
  return months;
}

export const formatDurationHelper = (duration: number) => {
  const time = {
    days: Math.floor(duration / (24 * 60 * 60)),
    hours: Math.floor((duration % (24 * 60 * 60)) / (60 * 60)),
    minutes: Math.floor((duration % (60 * 60)) / 60),
  };
  return formatDuration(time, { format: ['days', 'hours', 'minutes'] });
};

export const formatDateAgo = (date: string | number) => {
  return formatDistanceToNowStrict(new Date(date)) + ' ago';
};

export const subtractDateAndDays = (date: string | number, days: number) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() - days);
  return newDate.toISOString().split('T')[0];
};

export const subtractDateAndMinutes = (date: string | number, minutes: number) => {
  const newDate = new Date(date);
  newDate.setMinutes(newDate.getMinutes() - minutes);
  return newDate.toISOString().split('T')[0];
};
